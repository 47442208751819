


























































































































































































































































































































import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import { View, ViewItem, ViewItemType, ViewTypes } from '@/models'
import ComponentResizer from '@/components/tools/ComponentResizer.vue'
import draggable from 'vuedraggable'
import * as componentTypes from '@/components/componentTypes'
import { prompt } from '@/components/dialogs'

interface ChangeEventArgs {
  added?: {
    element: ViewItem
    newIndex: number
  }
  removed?: {
    element: ViewItem
    oldIndex: number
  }
}

@Component({
  components: {
    ComponentResizer,
    draggable
  }
})
export default class EnvironmentViewLayout extends Vue {
  @Prop({ type: String, required: true }) environmentId!: string
  @Prop({ type: Object, required: true }) view!: View
  @Prop({ type: Array, default: () => [] }) subItems!: ViewItem[]
  @Prop({ type: Object, default: () => ({}) }) viewParams!: Record<string, any>
  @Prop({ type: String, default: 'default' }) viewType?: ViewTypes
  @Prop({ type: Boolean, default: false }) tabTouchless!: boolean
  @Prop({ type: Boolean, default: false }) editing!: boolean
  @Prop({ type: Boolean, default: false }) preview!: boolean
  @Prop({ type: Boolean, default: false }) forceMobile!: boolean
  @Prop({ type: Boolean, default: false }) root!: boolean
  @Prop({ type: Boolean, default: false }) noCustomCSS!: boolean

  tab: number = 0
  settingsDialog: boolean = false
  touchless?: boolean = this.tabTouchless

  @Watch('view.customCss', { immediate: true })
  updateCustomCss() {
    if (this.noCustomCSS) return
    const style = document.getElementById('customViewCSS') as HTMLStyleElement
    style.innerText = (this.view.useCustomCss && this.view.customCss) || ''
  }

  saveSettings() {
    this.$emit('changeTouchless', this.touchless)
    this.settingsDialog = false
  }

  changeTouchless(index: number, value: boolean) {
    this.$emit('updateViewItem', [index], { touchless: value })
  }

  /** Get Sizes for a specific item */
  getSizes(item: ViewItem) {
    const sizes: Record<string, boolean> = {}
    sizes[`sm${item.sizeSmall || '12'}`] = true
    if (!this.forceMobile) {
      // sizes[`md${item.sizeMedium || '12'}`] = true
      // sizes[`lg${item.sizeLarge || '12'}`] = true
      sizes[`md${item.sizeLarge || '12'}`] = true
    }
    return sizes
  }

  resizeItem(item: ViewItem | any, newSize: number) {
    this.$emit(
      'updateViewItem',
      [item.index],
      this.forceMobile ? { sizeSmall: newSize } : { sizeLarge: newSize }
    )
  }

  calculateDirection(e: any, targetEl: HTMLElement, dragEl: HTMLElement) {
    const pfxClass =
      this.forceMobile || this.$vuetify.breakpoint.smAndDown ? 'col' : 'col-md'
    const pattern = new RegExp(`${pfxClass}-(\\d+)`)
    const targetElMatch = targetEl.className.match(pattern)
    const dragElMatch = dragEl.className.match(pattern)
    const targetSize = targetElMatch ? parseInt(targetElMatch[1], 10) : 12
    const dragSize = dragElMatch ? parseInt(dragElMatch[1], 10) : 12
    if (dragSize + targetSize > 12) {
      return 'vertical'
    } else {
      return 'horizontal'
    }
  }

  handleItemOperation(
    operation: 'add' | 'remove' | 'update',
    itemIndex: number,
    path: number[],
    updateValues: Partial<ViewItem>
  ) {
    this.$emit(operation + 'ViewItem', [itemIndex, ...path], updateValues)
  }

  handleSubItemsUpdate(itemIndex: number, data: ViewItem[]) {
    if (data.length !== this.subItems[itemIndex].subItems?.length) return
    this.$emit('updateViewItem', [itemIndex], { subItems: data })
  }

  handleChange({ added, removed }: ChangeEventArgs) {
    if (added) {
      this.$emit('addViewItem', [added.newIndex], added.element)
    }
    if (removed) {
      this.$emit('removeViewItem', [removed.oldIndex])
    }
  }

  handleEditComponent(
    itemIndex: number,
    type: string,
    id: string,
    path: number[]
  ) {
    this.$emit('editComponent', type, id, [itemIndex, ...path])
  }

  async editTabName(tab: ViewItem, tabIndex: number) {
    if (!this.editing) return
    const newName = await prompt('Introduce un nuevo nombre para la pestaña', {
      defaultValue: tab.tabName || `Pestaña ${tabIndex + 1}`
    })
    if (newName !== false) {
      this.$emit('updateViewItem', [tabIndex], { tabName: newName })
    }
  }

  setActiveTab(tabIndex: number) {
    this.tab = tabIndex
  }

  toggleBorder(index: number, item: ViewItem) {
    this.$emit('updateViewItem', [index], { forceBorders: !item.forceBorders })
  }

  get movableItems() {
    return this.subItems
  }

  set movableItems(v: ViewItem[]) {
    this.$emit('updateSubItems', v)
  }

  get items() {
    let sectionCount = 0
    let layoutCount = 0
    let tabsCount = 0
    return this.subItems
      .filter((i) => i)
      .map((i, index) => {
        if (i.type === ViewItemType.Section) {
          return {
            index,
            ...i,
            key: 'section-' + sectionCount++,
            titleSingle: this.editing ? 'Sección' : '',
            // sizes: this.getSizes(i),
            bind: {}
          }
        } else if (i.type === ViewItemType.Layout) {
          return {
            index,
            ...i,
            _id: 'layout-' + layoutCount,
            key: 'layout-' + layoutCount++,
            // sizes: this.getSizes(i),
            titleSingle: 'Sub-sección',
            view: EnvironmentViewLayout,
            bind: {
              view: this.view,
              editing: this.editing,
              viewType: i.viewType,
              subItems: i.subItems || [],
              tabTouchless: i.touchless
            }
          }
        } else {
          const matchingType = Object.keys(componentTypes)
            // @ts-ignore
            .find((t) => componentTypes[t].name === i.type)
          // @ts-ignore
          const id = i[`${i.type}Id`]

          const cssClasses: Record<string, boolean> = {}

          const cssClass = i.cssClass || ''
          cssClass
            .replace(/,\./g, '')
            .split(' ')
            .forEach((i) => {
              if (i.trim()) cssClasses[i.trim()] = true
            })

          return {
            index,
            ...i,
            // @ts-ignore
            ...componentTypes[matchingType],
            // @ts-ignore
            key: i.type + '-' + id,
            id,
            // sizes: this.getSizes(i),
            bind: {},
            cssClasses
          }
        }
      })
  }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.viewType === 'tabs')?_c('v-col',[_c('div',{staticStyle:{"display":"flex"}},[(!_vm.root && _vm.editing)?_c('v-flex',{key:"header",staticClass:"component-drag-handle",attrs:{"xs12":""},on:{"dblclick":function($event){return _vm.$emit('toggleBorder')}}},[_c('v-icon',[_vm._v("drag_handle")])],1):_vm._e(),(!_vm.root && _vm.editing)?_c('v-flex',{staticClass:"component-drag-handle",on:{"click":function($event){_vm.settingsDialog = !_vm.settingsDialog}}},[_c('v-icon',[_vm._v("settings")])],1):_vm._e()],1),_c('v-tabs',{attrs:{"background-color":"transparent","show-arrows":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('draggable',{staticClass:"v-slide-group__content v-tabs-bar__content",style:({
          'min-height': _vm.items.length ? undefined : '100px',
          width: _vm.items.length ? undefined : '100%'
        }),attrs:{"disabled":!_vm.editing,"draggable":".v-tab","group":{ name: 'view-components' },"animation":200,"ghost-class":"ghost","scroll-sensitivity":128,"force-fallback":true,"empty-insert-threshold":128,"direction":"horizontal","drag-class":"invisible-drag"},on:{"change":_vm.handleChange,"start":function($event){return _vm.$emit('dragging', true)},"end":function($event){return _vm.$emit('dragging', false)}},model:{value:(_vm.movableItems),callback:function ($$v) {_vm.movableItems=$$v},expression:"movableItems"}},_vm._l((_vm.items),function(item,i){return _c('v-tab',{key:item.key,style:({ cursor: _vm.editing ? 'move' : undefined }),domProps:{"textContent":_vm._s(item.tabName || ("Pestaña " + (i + 1)))},on:{"dblclick":function($event){return _vm.editTabName(item, i)}}})}),1)],1),_c('v-tabs-items',{staticClass:"transparent",attrs:{"touchless":_vm.tabTouchless},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.items),function(item,i){
        var _obj;
return _c('v-tab-item',{key:item.key,class:Object.assign(( _obj = {}, _obj[((item.type) + "-component")] = true, _obj ),
          {'component-container': true},
          {editing: _vm.editing},
          {'component--no-borders':
            _vm.view.hideBorders ||
            item.hideBorders ||
            item.type === 'section' ||
            item.type === 'layout'},
          {'pa-1': item.type !== 'layout'},
          {'d-print-none': item.hidePrint},
          {'tab-content': true},
          item.cssClasses),attrs:{"eager":i === 0}},[_c(item.view,_vm._b({tag:"component",attrs:{"id":item.key,"environmentId":_vm.environmentId,"componentId":item.id,"itemDefinition":item,"viewParams":_vm.viewParams,"preview":_vm.preview,"editing":_vm.editing,"forceMobile":_vm.forceMobile,"skeleton":item.viewSkeleton},on:{"setParams":function($event){return _vm.$emit('setParams', $event)},"updateViewItem":function($event){
        var i = arguments.length, argsArray = Array(i);
        while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleItemOperation.apply(void 0, [ 'update', item.index ].concat( argsArray ))},"addViewItem":function($event){
        var i = arguments.length, argsArray = Array(i);
        while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleItemOperation.apply(void 0, [ 'add', item.index ].concat( argsArray ))},"removeViewItem":function($event){
        var i = arguments.length, argsArray = Array(i);
        while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleItemOperation.apply(void 0, [ 'remove', item.index ].concat( argsArray ))},"updateSubItems":function($event){
        var i = arguments.length, argsArray = Array(i);
        while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleSubItemsUpdate.apply(void 0, [ item.index ].concat( argsArray ))},"dragging":function($event){return _vm.$emit('dragging', $event)},"edit":function($event){return _vm.$emit('editComponent', item.namespace, item.id, [item.index])},"editComponent":function($event){
        var i = arguments.length, argsArray = Array(i);
        while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleEditComponent.apply(void 0, [ item.index ].concat( argsArray ))},"toggleBorder":function($event){
        var i = arguments.length, argsArray = Array(i);
        while ( i-- ) argsArray[i] = arguments[i];
return _vm.toggleBorder.apply(void 0, [ item.index, item ].concat( argsArray ))},"changeTouchless":function($event){
        var i = arguments.length, argsArray = Array(i);
        while ( i-- ) argsArray[i] = arguments[i];
return _vm.changeTouchless.apply(void 0, [ item.index ].concat( argsArray ))}}},'component',item.bind,false))],1)}),1)],1):_c('draggable',{staticClass:"row no-gutters view-components",attrs:{"disabled":!_vm.editing,"draggable":".editing","handle":".component-drag-handle","group":{ name: 'view-components' },"animation":200,"ghost-class":"ghost","swap-threshold":0.5,"empty-insert-threshold":64,"scroll-sensitivity":128,"direction":_vm.calculateDirection,"force-fallback":true,"drag-class":"invisible-drag"},on:{"change":_vm.handleChange,"start":function($event){return _vm.$emit('dragging', true)},"end":function($event){return _vm.$emit('dragging', false)}},model:{value:(_vm.movableItems),callback:function ($$v) {_vm.movableItems=$$v},expression:"movableItems"}},[_vm._l((_vm.items),function(item){
        var _obj, _obj$1;
return _c('v-col',{key:item.key,class:Object.assign(( _obj = {}, _obj[((item.type) + "-component")] = true, _obj ),
        {'component-container': true},
        {editing: _vm.editing},
        {'component--no-borders':
          !item.forceBorders &&
          (_vm.view.hideBorders ||
            item.hideBorders ||
            item.type === 'section' ||
            item.type === 'layout')},
        {'pa-1': item.type !== 'layout'},
        {'d-print-none': item.hidePrint},
        item.cssClasses),attrs:{"id":item.key,"cols":item.sizeSmall || '12',"md":!_vm.forceMobile && item.sizeLarge}},[(
          item.forceBorders ||
          (!_vm.view.hideBorders &&
            !item.hideBorders &&
            item.type !== 'section' &&
            item.type !== 'layout' &&
            item.type !== 'tabs')
        )?_c('v-card',{staticClass:"view-component-content",attrs:{"title":_vm.editing && item.titleSingle}},[(_vm.editing)?_c('v-divider',{class:( _obj$1 = {}, _obj$1[item.color] = true, _obj$1 )}):_vm._e(),_c(item.view,_vm._b({tag:"component",attrs:{"environmentId":_vm.environmentId,"componentId":item.id,"itemDefinition":item,"viewParams":_vm.viewParams,"preview":_vm.preview,"editing":_vm.editing,"forceMobile":_vm.forceMobile,"skeleton":item.viewSkeleton},on:{"setParams":function($event){return _vm.$emit('setParams', $event)},"updateViewItem":function($event){
        var i = arguments.length, argsArray = Array(i);
        while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleItemOperation.apply(void 0, [ 'update', item.index ].concat( argsArray ))},"addViewItem":function($event){
        var i = arguments.length, argsArray = Array(i);
        while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleItemOperation.apply(void 0, [ 'add', item.index ].concat( argsArray ))},"removeViewItem":function($event){
        var i = arguments.length, argsArray = Array(i);
        while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleItemOperation.apply(void 0, [ 'remove', item.index ].concat( argsArray ))},"updateSubItems":function($event){
        var i = arguments.length, argsArray = Array(i);
        while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleSubItemsUpdate.apply(void 0, [ item.index ].concat( argsArray ))},"dragging":function($event){return _vm.$emit('dragging', $event)},"edit":function($event){return _vm.$emit('editComponent', item.namespace, item.id, [item.index])},"editComponent":function($event){
        var i = arguments.length, argsArray = Array(i);
        while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleEditComponent.apply(void 0, [ item.index ].concat( argsArray ))},"toggleBorder":function($event){
        var i = arguments.length, argsArray = Array(i);
        while ( i-- ) argsArray[i] = arguments[i];
return _vm.toggleBorder.apply(void 0, [ item.index, item ].concat( argsArray ))},"changeTouchless":function($event){
        var i = arguments.length, argsArray = Array(i);
        while ( i-- ) argsArray[i] = arguments[i];
return _vm.changeTouchless.apply(void 0, [ item.index ].concat( argsArray ))}}},'component',item.bind,false)),(_vm.editing)?_c('ComponentResizer',{attrs:{"value":_vm.forceMobile ? item.sizeSmall : item.sizeLarge,"color":item.color},on:{"input":function($event){return _vm.resizeItem(item, $event)}}}):_vm._e()],1):_c('div',{staticClass:"view-component-content",attrs:{"title":_vm.editing && item.titleSingle}},[(_vm.editing)?_c('v-divider',{staticClass:"transparent"}):_vm._e(),_c(item.view,_vm._b({tag:"component",attrs:{"environmentId":_vm.environmentId,"componentId":item.id,"itemDefinition":item,"viewParams":_vm.viewParams,"preview":_vm.preview,"editing":_vm.editing,"forceMobile":_vm.forceMobile,"skeleton":item.viewSkeleton},on:{"setParams":function($event){return _vm.$emit('setParams', $event)},"updateViewItem":function($event){
        var i = arguments.length, argsArray = Array(i);
        while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleItemOperation.apply(void 0, [ 'update', item.index ].concat( argsArray ))},"addViewItem":function($event){
        var i = arguments.length, argsArray = Array(i);
        while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleItemOperation.apply(void 0, [ 'add', item.index ].concat( argsArray ))},"removeViewItem":function($event){
        var i = arguments.length, argsArray = Array(i);
        while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleItemOperation.apply(void 0, [ 'remove', item.index ].concat( argsArray ))},"updateSubItems":function($event){
        var i = arguments.length, argsArray = Array(i);
        while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleSubItemsUpdate.apply(void 0, [ item.index ].concat( argsArray ))},"dragging":function($event){return _vm.$emit('dragging', $event)},"edit":function($event){return _vm.$emit('editComponent', item.namespace, item.id, [item.index])},"editComponent":function($event){
        var i = arguments.length, argsArray = Array(i);
        while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleEditComponent.apply(void 0, [ item.index ].concat( argsArray ))},"toggleBorder":function($event){
        var i = arguments.length, argsArray = Array(i);
        while ( i-- ) argsArray[i] = arguments[i];
return _vm.toggleBorder.apply(void 0, [ item.index, item ].concat( argsArray ))},"changeTouchless":function($event){
        var i = arguments.length, argsArray = Array(i);
        while ( i-- ) argsArray[i] = arguments[i];
return _vm.changeTouchless.apply(void 0, [ item.index ].concat( argsArray ))}}},'component',item.bind,false)),(_vm.editing)?_c('ComponentResizer',{attrs:{"value":_vm.forceMobile ? item.sizeSmall : item.sizeLarge,"color":item.color,"invisibleHandle":""},on:{"input":function($event){return _vm.resizeItem(item, $event)}}}):_vm._e()],1)],1)}),(!_vm.root && _vm.editing)?_c('v-flex',{key:"header",staticClass:"component-drag-handle",attrs:{"slot":"header","xs12":""},on:{"dblclick":function($event){return _vm.$emit('toggleBorder')}},slot:"header"},[_c('v-icon',[_vm._v("drag_handle")])],1):_vm._e()],2),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.settingsDialog),callback:function ($$v) {_vm.settingsDialog=$$v},expression:"settingsDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Configuración de componente ")]),_c('v-card-text',{staticStyle:{"padding-block":"0px"}},[_c('v-checkbox',{attrs:{"label":"Desactivar Swipe en modo responsive"},model:{value:(_vm.touchless),callback:function ($$v) {_vm.touchless=$$v},expression:"touchless"}})],1),_c('v-card-actions',{staticStyle:{"padding-inline":"20px","padding-bottom":"15px"}},[_c('v-btn',{attrs:{"dark":"","color":"#405ac2"},on:{"click":_vm.saveSettings}},[_vm._v(" Guardar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }